import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ViewportBlock from "../viewportBlock";

const Article = ({ data, styles }) => {
  const [imgAvailable] = useState(data.image != null ? true : false)
  let image = ''
  if(data.image != null){
    image = getImage(data.image.imageFile)
  }
  function addZ(n){return n<10? '0'+n:''+n;}
  const date = new Date(data.date);
  const day = addZ(date.toLocaleDateString('ru-RU', {day: 'numeric'}))
  const month = date.toLocaleDateString('ru-RU', {month: 'long'}).substring(0,3)
  const year = date.toLocaleDateString('ru-RU', {year: 'numeric'})
  const finalDate = "Опубликовано "+month+" "+day+", "+year
  return (
      <ViewportBlock className={styles.articleItem + ' reveal'}>
        {imgAvailable ? <div className={styles.image}><Link to={"/news/"+data.url}><GatsbyImage image={image} alt={data.title} /></Link></div> : "" }
        <div className={styles.datePublished}>
           {finalDate}
        </div>
        <h3 className={styles.articleMinititle}>
          <Link to={"/news/"+data.url}>{data.title}</Link>
        </h3>
        <div className={styles.articleSectionDescription}>
          <span dangerouslySetInnerHTML={{ __html: data.preview_content}} />
        </div>
      </ViewportBlock>
  )
}

export default Article
