// extracted by mini-css-extract-plugin
export var articleItem = "news-module--articleItem--s--i5";
export var articleMinititle = "news-module--articleMinititle--i53hD";
export var articleSectionDescription = "news-module--articleSectionDescription--azIes";
export var datePublished = "news-module--datePublished--O60wm";
export var image = "news-module--image--QnCDE";
export var newsRow = "news-module--newsRow--hS5Ae";
export var newsSection = "news-module--newsSection--c3uuK";
export var newsSectionFoot = "news-module--newsSectionFoot--w5VxA";
export var newsSectionHead = "news-module--newsSectionHead--EkJpg";
export var newsSectionTitle = "news-module--newsSectionTitle--o464M";
export var seeAllBtn = "news-module--seeAllBtn--G8xfp";