import * as React from "react"
import Article from "../components/mainpage/Article"
import ContactusSection from "../components/mainpage/ContactusSection"
import FooterSection from "../components/mainpage/FooterSection"
import HeaderSection from "../components/HeaderSection"
import Menuzord from "../components/mainpage/Menuzord"
import { graphql } from "gatsby"
import Seo from "../components/seo"
// styles
import * as styles from "../css/news.module.css"


// markup
const NewsPage = ({ data }) => {
  const articlestyles = {
    articleItem : styles.articleItem,
    image : styles.image,
    datePublished : styles.datePublished,
    articleMinititle : styles.articleMinititle,
    articleSectionDescription : styles.articleSectionDescription
  }
  const nodes = data.directus.articles;
  const { imageFile } = data.directus_system.files[0];
  return (
    <>
      <Seo title="Новости"/>
      <Menuzord pageName="news" />
      <HeaderSection backgroundImage={imageFile}>Держите себя в курсе: <strong>Наши последние новости</strong></HeaderSection>
      <section className={styles.newsSection}>
        <div className="container">
          <div className={styles.newsRow}>
            {nodes.map(article => {
              return (
                <Article key={article.id} data={article} styles={articlestyles}></Article>
              )
            })}
          </div>
        </div>
      </section>
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default NewsPage

export const query = graphql`
  query NewsItem {
    directus {
      articles {
        id
        title
        date
        preview_content
        url
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          id
        }
      }
    }
    directus_system {
      files(filter: {title: {_eq: "News Background"}}) {
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        id
      }
    }
  }
`